// @flow
import * as React from 'react';
import cx from 'classnames';

import { getLocale } from '../../i18n';
import ANALYTICS from '../../analytics';
import Button from '../../button';

import cs from './styles.pcss';
import Arrow from './arrow.svg';

type Lang = {
  name: string,
  title: string,
  id: string,
};

type Props = {
  url?: string,
};

const getLangs = (): Lang[] => [
  { name: 'en', title: 'English', id: 'en' },
  { name: 'cn', title: '简体中文', id: 'zh' },
  { name: 'ja', title: '日本語', id: 'ja' },
  { name: 'ko', title: '한국어', id: 'ko' },
  // { name: 'ру', title: 'Русский', id: 'ru' },
  { name: 'es', title: 'Español', id: 'es' },
  { name: 'fr', title: 'Français', id: 'fr' },
  { name: 'it', title: 'Italiano', id: 'it' },
  { name: 'pt', title: 'Português', id: 'pt' },
  { name: 'de', title: 'Deutsch', id: 'de' },
  { name: 'pl', title: 'Polski', id: 'pl' },
];

const renderLink = (current: string, url?: string) => (l: Lang) =>
  (
    <a
      href={`${url || ''}/?locale=${l.id}`}
      title={l.title}
      key={l.id}
      className={cx(cs.link, { [cs.currentLink]: l.id === current })}
      onClick={() => ANALYTICS.languageSwitch(l.id)}
    >
      {l.title}
    </a>
  );

export default function LangSwitcher({ url }: Props): React.Node {
  const [opened, setOpened] = React.useState(false);

  const component = React.useRef();

  const handleClose = (event: MouseEvent) => {
    const comp = component && component.current;
    if (
      comp &&
      event.target !== comp &&
      !(event.target instanceof Node && comp.contains(event.target))
    ) {
      setOpened(false);
    }
  };

  React.useEffect(() => {
    if (typeof document !== 'undefined' && document.body) {
      document.body.addEventListener('click', handleClose);
    }

    return () => {
      if (typeof document !== 'undefined' && document.body) {
        document.body.removeEventListener('click', handleClose);
      }
    };
  }, []);

  const handleToggle = () => {
    setOpened((prevOpened) => !prevOpened);
  };

  const current = getLocale();
  const langs = getLangs();
  const currentLocale = langs.find((l) => l.id === current) || langs[0];

  return (
    <div className={cs.langSwitcher} ref={component}>
      <Button
        mode="secondary"
        size="small"
        className={cx(cs.current, { [cs.opened]: opened })}
        onClick={handleToggle}
      >
        <div className={cs.buttonContent}>
          <div>{currentLocale.name}</div>
          <Arrow className={cs.arrow} />
        </div>
      </Button>

      <div
        className={cx(cs.content, {
          [cs.opened]: opened,
        })}
      >
        <div className={cs.dropdown}>{langs.map(renderLink(current, url))}</div>
      </div>
    </div>
  );
}
